import axios from 'axios';
import { DeviceInfo } from '@/model/DeviceInfo'
import { DevicesResponse, SearchRequestBody, SearchResponseBody } from '@/services/model/GetPartyListResponse'
import { TrackInfo } from '@/model/TrackInfo';
import { PlaylistInfo } from '@/model/PlaylistInfo';
import { GetPlayListResponse } from './model/GetPlayListResponse';
import { AccessTokenResponse } from './model/AccessTokenResponse';
import { environment } from '../environment/environment';

class SpotifyService {
    public async getDevices(): Promise<DeviceInfo[]> {
        const response = await axios.get<DevicesResponse>(`${environment.apiUrl}/api/spotify/devices`);
        return response.data.devices;
    }

    public async getTracks(partyId: string, searchString: string) : Promise<TrackInfo[]> {
        const response = await axios
            .post<SearchResponseBody>(
                `${environment.apiUrl}/api/spotify/search`,
                new SearchRequestBody(partyId, searchString)
            );

        return response.data.tracks;
    }

    public async getPlayLists(): Promise<PlaylistInfo[]>{
        const response = await axios.get<GetPlayListResponse>(`${environment.apiUrl}/api/spotify/playlists`);
        return response.data.items;
    }

    public async getAccessToken(partyId: string): Promise<AccessTokenResponse>{
        const response = await axios.get<AccessTokenResponse>(`${environment.apiUrl}/api/spotify/accesstoken/${partyId}`);

        console.log(`received token: ${response.data.token}`);

        return response.data;
    }
}

export default new SpotifyService()
