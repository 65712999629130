import { defineComponent, ref } from 'vue';
import { OptionsViewModel } from '@/model/OptionsViewModel'
import { PartyInfo } from '@/model/PartyInfo'
import { PlaylistInfo } from '@/model/PlaylistInfo'
import { PictureInfo } from '../model/PictureInfo';
import Swal from 'sweetalert2';
import { PartyState } from '../model/PartyState';
import _partyService from '@/services/partyService'
import _spotifyService from '@/services/SpotifyService'
import _partyHost from '@/services/PartyHostService'

export default defineComponent({
    name: 'AccountPage',
    setup() {
        const partyInfo = ref(new PartyInfo('', '', ''));
        const partyInfoList = ref < PartyInfo[] > ([]);
        //TODO: get this list from api
        const playListOptions = ref < PlaylistInfo[] > ([]);

        const showAddPartyDialog = ref(false);
        const spotifyPlayerList = ref < OptionsViewModel[] > ([]);
        const selectedPlayerId = ref < string | null > (null);
        const pictureList = ref < PictureInfo[] > ([]);

        return {
            showAddPartyDialog,
            partyInfo,
            partyInfoList,
            playListOptions,
            spotifyPlayerList,
            selectedPlayerId,
            pictureList
        }
    },
    mounted() {
        this.getPartyInfoList();
        this.getPlayerList();
        this.getPlayLists();
    },
    created() {
        _partyHost.Connected.on(() => {
            console.log('OnConnected: IsConnected:', _partyHost.IsConnected);
        });
    },
    methods: {
        getPlayerList() {
            this.spotifyPlayerList.length = 0;

            _spotifyService.getDevices()
                .then(devices => {
                    devices.forEach((player) => {
                        this.spotifyPlayerList.push({ value: player.id, text: player.name });
                    })
                });
        },

        getPartyInfoList() {
            this.partyInfoList.length = 0;

            _partyService.getPartyList()
                .then(list => list
                    .forEach((party) => {
                        this.partyInfoList.push(party);
                    })
                );
        },

        getPlayLists() {
            this.playListOptions.length = 0;

            _spotifyService.getPlayLists()
                .then(list => list
                    .forEach(playList => {
                        this.playListOptions.push(playList);
                    }))
        },

        selectParty(party: PartyInfo): void {
            console.log(`selected party ${party.id}`);
            this.partyInfo = party;

            //todo:
            //this.selectedPlayerId = this.spotifyPlayerList.find(player => player.text == party.playerId)?.value ?? null;

            this.loadPictures(party.id);
        },

        deleteParty(party: PartyInfo): void {
            console.log(`removing party ${party.id}`);
            Swal.fire({
                title: `Weet je zeker dat je de Party '${party.title}' wilt verwijderen?`, 
                showCancelButton: true,
                confirmButtonText: `Delete`,
            })
            .then((result) => {
                if(result.isConfirmed) 
                {
                    _partyService
                        .deleteParty(party.id)
                        .then(() => {
                            this.getPartyInfoList();
                            this.resetPartyInfo();
                        });
                }
            });

        },

        loadPictures(partyId: string): void {
            this.pictureList.length = 0;

            _partyService.getPictureUrls(partyId)
                .then(pictureList => {
                    pictureList.pictureIdList.forEach((picture) => {
                        this.pictureList.push(
                            new PictureInfo(
                                picture,
                                `/api/picture/small/${partyId}/${picture}`
                            )
                        )
                    });
                });
        },

        deletePicture(partyId: string, pictureId: string): void {
            Swal.fire({
                    title: 'Weet je zeker dat je deze foto wilt verwijderen?',
                    showCancelButton: true,
                    confirmButtonText: `Delete`,
                })
                .then((result) => {
                    if(result.isConfirmed) 
                    {
                        _partyService
                            .deletePicture(partyId, pictureId)
                            .then(() => {
                                this.loadPictures(partyId);
                            });
                    }
                }
            );
        },

        resetPartyInfo(): void {
            this.partyInfo = {
                id: '',
                title: '',
                fallbackPlaylist: null,
                status: PartyState.Unknown,
            };
        },

        partyListVisiblity(): Record<string, boolean> {
            return {
                partyList: this.partyInfo?.id === '',
                partyListSelected: this.partyInfo?.id !== '',
            };
        },

        onCancel(): void {
            this.showAddPartyDialog = false;
        },

        onAddParty(): void {
            // if (!this.$refs.form.checkValidity())
            //     return;

            console.log(this.partyInfo);

            _partyService.createParty(this.partyInfo)
                .then(() => {
                    this.getPartyInfoList();
                });

            this.$nextTick(() => {
                this.showAddPartyDialog = false;
            });
        },

        onStart(partyId: string, playerId: string | null): void {
            this.$router.push({
                name: 'Party',
                query: { partyId, playerId }
            });
        },

        startNextTrack(partyId: string): void {
            console.log('move to next Track. party connected=', _partyHost.IsConnected);
            if (_partyHost.IsConnected)
                _partyHost.StartNextTrack(partyId).then();
        }
    },
});
